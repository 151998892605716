import { Grid, Text } from '@chakra-ui/react';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import NavLink from '@/components/UI/Links/NavLink';

import RoutePath from '@/constants/route-path';

type RegisterEncourageProps = {
    fromBasket?: boolean;
};

export const RegisterEncourage: FunctionComponent<RegisterEncourageProps> = ({ fromBasket }) => {
    return (
        <Grid mt={fromBasket ? 0 : 8} justifyContent={fromBasket ? 'start' : 'center'} gap={8}>
            <Text fontWeight={fromBasket ? 'regular' : 'bold'}>
                <FormattedMessage id="page.login.encourage.text" />
            </Text>
            <NavLink
                variant="buttonBrand"
                fontWeight="semibold"
                mx="auto"
                w={fromBasket ? 'full' : 'auto'}
                href={RoutePath.Register}
                textAlign="center"
                px={4}
                py={2}
                _hover={{
                    bgColor: 'brand.darkest'
                }}
            >
                <FormattedMessage id="page.login.create-account" />
            </NavLink>
        </Grid>
    );
};
